<script>
import { socials } from '#/constants';
</script>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  type: {
    type: String,
    default: undefined,
    validator(value) {
      return socials[value] !== undefined;
    },
  },
  color: {
    type: String,
    default: undefined,
  },
  icon: {
    type: String,
    default: undefined,
  },
});

const social = computed(() => props.type && socials[props.type]);
const bgColor = computed(() => props.color || social.value?.color);
const bgImage = computed(() => props.icon || social.value?.icon_static);
</script>

<template>
  <a
    class="inline-block h-8 rounded-full bg-white pr-4 shadow-gray dark:bg-gray-800"
    target="_blank"
  >
    <i
      class="mr-1 inline-block size-8 rounded-full align-middle"
      :style="{ backgroundColor: bgColor, backgroundImage: `url(${bgImage})` }"
    />
    <span class="text-xxs leading-4 text-gray-600 dark:text-gray-100">
      <slot />
    </span>
  </a>
</template>
