import axios from 'axios';
import useReportErrorInterceptor from '../utils/report-error';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URI + '/reports',
  headers: { Accept: 'application/json' },
  withCredentials: true,
});
useReportErrorInterceptor(instance);

/**
 * @param {number} targetId
 * @param {"Event" | "Comment" | "User"} targetType
 * @param {string} reason
 * @param {string} comment
 */
export async function create(targetId, targetType, reason, comment) {
  const result = await instance.post('', {
    sentOn: targetType,
    onId: targetId,
    reason: reason,
    comment: comment,
  });
  return result.data;
}

// eslint-disable-next-line no-unused-vars
export async function get(options) {
  throw new Error('Not Implemented');
}

// eslint-disable-next-line no-unused-vars
export async function list(length = 10) {
  throw new Error('Not Implemented');
}
