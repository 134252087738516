import axios from 'axios';
import { NoAccessError } from './errors';
import useReportErrorInterceptor from '../utils/report-error';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URI + '/users',
  headers: { Accept: 'application/json' },
  withCredentials: true,
});
useReportErrorInterceptor(instance);

export async function get(user_id) {
  try {
    const response = await instance.get(`/${user_id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function list({ offset, limit } = {}) {
  try {
    const response = await instance.get('', { params: { offset, limit } });
    return response.data;
  } catch (error) {
    if (error.response || error.response.status === 403) {
      throw new NoAccessError(undefined, { cause: error.response.data });
    }
    throw error;
  }
}

export async function search(value) {
  const response = await instance.get('/filtered/contain_in_fullName', {
    params: { filterValue: value },
  });
  return response.data;
}
