<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="#4285F4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6943 12.0747C18.7426 11.1559 17.532 10.688 16.1837 10.688C13.7917 10.688 11.7672 12.3192 11.045 14.5109C10.8613 15.0673 10.757 15.6616 10.757 16.2728C10.757 16.8839 10.8613 17.4782 11.045 18.0345C11.7671 20.2263 13.7917 21.8574 16.1837 21.8574C17.4193 21.8574 18.4712 21.5287 19.2936 20.9723C20.2662 20.3148 20.9133 19.3327 21.1262 18.1737H16.1837V14.5868H24.833C24.9416 15.1937 25 15.826 25 16.4835C25 19.3075 23.9981 21.6847 22.2616 23.299C20.7421 24.7151 18.6632 25.5455 16.1837 25.5455C12.5937 25.5455 9.48797 23.4675 7.97683 20.4371C7.35484 19.1852 7 17.769 7 16.2728C7 14.7765 7.35482 13.3603 7.97681 12.1085C9.48794 9.07795 12.5937 7 16.1837 7C18.6591 7 20.7379 7.91884 22.3284 9.41513L19.6943 12.0747Z"
    />
  </svg>
</template>
