import axios from 'axios';
import useReportErrorInterceptor from '../utils/report-error';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URI + '/reactions',
  headers: { Accept: 'application/json' },
  withCredentials: true,
});
useReportErrorInterceptor(instance);

export async function list(eventId) {
  console.log(eventId);
  const response = await instance.get('', { params: { eventId } });
  return response.data.map((i) => ({ code: i.code, count: i._count.code }));
}

/**
 * @returns { Promise<CardanoReaction> }
 */
export async function create(eventId, code) {
  const response = await instance.post('', { code, eventId });
  return response.data;
}
