<script setup>
import ToggleTheme from '#/components/ToggleTheme.vue';
import ProfileAvatarMobile from '#/components/profile/ProfileAvatarMobile.vue';
import CardanoCalendarLogoOnly from '#/elements/CardanoCalendarLogoOnly.vue';
import LoupeIcon from '#/elements/icons/LoupeIcon.vue';
import { useUserStore } from '#/store/user';
import { Dialog, DialogPanel } from '@headlessui/vue';
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';

import { ref } from 'vue';

const userStore = useUserStore();

const mobileMenuOpen = ref(false);
</script>

<template>
  <header class="px-4 sm:px-8">
    <div class="mx-auto flex max-w-xxl justify-between gap-4 py-6">
      <div class="flex">
        <button
          type="button"
          class="bg-gradiend-gray-light dark:bg-gradiend-gray-dark inline-flex items-center justify-center self-center rounded-full p-2.5 text-gray-700"
          @click="mobileMenuOpen = true"
        >
          <span class="sr-only">Open main menu</span>
          <Bars3Icon
            class="size-6 text-white dark:text-gray-400"
            aria-hidden="true"
          />
        </button>
      </div>

      <div class="relative flex grow items-center">
        <div class="flex grow justify-center">
          <RouterLink :to="{ name: 'Main' }">
            <span class="sr-only">Cardano Calendar</span>
            <CardanoCalendarLogoOnly aria-hidden="true" />
          </RouterLink>
        </div>

        <div class="group relative inset-x-0 focus-within:absolute">
          <div class="right-0 text-gray-400 focus-within:text-gray-600">
            <label
              for="header-search-mobile"
              class="absolute inset-y-0 left-3 flex items-center"
            >
              <span class="sr-only">Search</span>
              <LoupeIcon
                class="size-7 fill-gray-500 group-focus-within:size-4 dark:fill-gray-600"
                aria-hidden="true"
              />
            </label>

            <input
              id="header-search-mobile"
              class="block w-0 rounded-lg border border-transparent bg-white py-1.5 pl-9 pr-3 text-gray-900 placeholder:text-gray-600 focus:border-primary focus:ring-1 focus:ring-primary focus-visible:outline-none group-focus-within:w-full dark:bg-gray-800 dark:text-gray-100 dark:placeholder:text-gray-400 dark:focus:border-primary sm:text-xs sm:leading-5.5"
              placeholder="Search"
              type="search"
              name="search"
              @keydown.enter="
                $router.push({
                  name: 'SearchResult',
                  query: {
                    searchBy: $event.target.value,
                    searchTarget: $route.query.searchTarget,
                  },
                })
              "
            >
          </div>
        </div>
      </div>

      <Dialog
        as="div"
        class="xl:hidden"
        :open="mobileMenuOpen"
        @close="mobileMenuOpen = false"
      >
        <div class="fixed inset-0 z-10" />
        <DialogPanel
          class="no-scrollbar fixed inset-y-0 left-0 z-10 w-full overflow-y-auto bg-primary px-4 py-6 sm:max-w-md sm:px-8"
        >
          <div class="flex items-center justify-between">
            <button
              type="button"
              class="rounded-full bg-primary-extraligth p-2.5 text-gray-700"
              @click="mobileMenuOpen = false"
            >
              <span class="sr-only">Close menu</span>
              <XMarkIcon
                class="size-6"
                aria-hidden="true"
              />
            </button>

            <RouterLink :to="{ name: 'Main' }">
              <CardanoCalendarLogoOnly class="fill-primary-extraligth" />
            </RouterLink>
          </div>

          <div class="mt-6 flow-root">
            <div class="flex flex-col gap-8">
              <template v-if="userStore.isAuthorized">
                <ProfileAvatarMobile />
                <div class="flex flex-col">
                  <RouterLink
                    :to="{ name: 'UserProfileAccount' }"
                    class="-mx-3 block rounded-lg p-3 text-xl font-semibold leading-7 text-white"
                  >
                    Account
                  </RouterLink>
                  <RouterLink
                    :to="{ name: 'UserProfileBio' }"
                    class="-mx-3 block rounded-lg p-3 text-xl font-semibold leading-7 text-white"
                  >
                    Bio
                  </RouterLink>
                  <RouterLink
                    :to="{ name: 'UserProfileMyEvents' }"
                    class="-mx-3 block rounded-lg p-3 text-xl font-semibold leading-7 text-white"
                  >
                    My Events
                  </RouterLink>
                  <RouterLink
                    :to="{ name: 'UserProfileAttendedEvents' }"
                    class="-mx-3 block rounded-lg p-3 text-xl font-semibold leading-7 text-white"
                  >
                    Attended Events
                  </RouterLink>
                  <RouterLink
                    :to="{ name: 'UserProfileIntegrations' }"
                    class="-mx-3 block rounded-lg p-3 text-xl font-semibold leading-7 text-white"
                  >
                    Integrations
                  </RouterLink>
                  <RouterLink
                    :to="{ name: 'UserProfileNotifications' }"
                    class="-mx-3 block rounded-lg p-3 text-xl font-semibold leading-7 text-white"
                  >
                    Notifications
                  </RouterLink>
                  <RouterLink
                    :to="{ name: 'UserProfileWallet' }"
                    class="-mx-3 block rounded-lg p-3 text-xl font-semibold leading-7 text-white"
                  >
                    Wallet
                  </RouterLink>
                </div>

                <RouterLink
                  :to="{ name: 'CreateEvent' }"
                  class="w-full self-stretch rounded-full bg-primary-extraligth px-12 py-3.5 text-center text-sm font-bold text-primary-dark"
                >
                  Create event
                </RouterLink>

                <div class="flex items-center gap-4 font-semibold text-white">
                  <span>Light mode</span>
                  <ToggleTheme class="ring-white aria-checked:ring-2" />
                  <span>Dark mode</span>
                </div>

                <div><!-- Spacer --></div>

                <button
                  type="button"
                  class="-mx-3 block rounded-lg px-3 py-2.5 text-left text-base font-semibold leading-7 text-white"
                  @click="
                    async () => {
                      await userStore.logout();
                      $router.push({ name: 'Main' });
                    }
                  "
                >
                  Log Out
                </button>
              </template>
              <template v-else>
                <RouterLink
                  :to="{ name: 'SignUp' }"
                  class="my-8 w-full self-stretch rounded-full bg-primary-extraligth px-12 py-3.5 text-center text-sm font-bold text-primary-dark"
                >
                  Sign up
                </RouterLink>
              </template>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </div>
  </header>
</template>
