import axios from 'axios';
import useReportErrorInterceptor from '../utils/report-error.js';
import { MISSING_TOKEN } from './error-codes.js';
import { ApiError } from './errors.js';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URI + '/auth',
  headers: { Accept: 'application/json' },
  withCredentials: true,
});
useReportErrorInterceptor(instance);

/**
 * @returns {Promise<CardanoUserInfo>}
 */
export async function register(email, password, fullName) {
  try {
    const response = await instance.post('/registration', { email, password, fullName });
    return response.data;
  } catch (error) {
    const response = error.response;
    if (response.status === 400) {
      throw new ApiError(error.response.data);
    }
    throw error;
  }
}
/**
 * Perform login using user's credentials
 * @param {string} email
 * @param {string} password
 * @returns {Promise<CardanoUserInfo>}
 */
export async function login(email, password) {
  try {
    const response = await instance.post('/login', { email, password });
    return response.data;
  } catch (error) {
    const response = error.response;
    if (response.status === 400) {
      throw new ApiError(error.response.data);
    }
    throw error;
  }
}
/** @deprecated */
export async function refresh() {
  const response = await instance.get('/refresh', {
    validateStatus: (status) => status === 200 || status === 401,
  });
  if (response.status === 401 && response.data.code === MISSING_TOKEN) {
    return false;
  }
  return true;
}
export async function logout() {
  await instance.post('/logout');
  return true;
}

export async function recoverPassword(email, return_path) {
  const response = await instance.post(
    '/forgot_password',
    { email },
    { params: { redirect_path: return_path } },
  );
  return response.data;
}
export async function resetPassword(token, password) {
  const response = await instance.post('/reset_password', {
    token,
    password,
  });
  return response.data;
}
export function getOAuthGoogleLink() {
  return instance.defaults.baseURL + "/oauth2/google";
}
export function getOAuthTwitterLink() {
  return instance.defaults.baseURL + "/oauth1/twitter";
}

/** @deprecated */
export async function getGoogleOAuthLoginURL() {
  const response = await instance.get('/login/oauth2/google');
  return response.data.authorizationUrl;
}
/** @deprecated */
export async function confirmGoogleOAuthLogin(code) {
  const response = await instance.post('/login/oauth2/google/callback', {
    code,
  });
  return response.data;
}
/** @deprecated */
export async function getTwitterOAuthLoginURL() {
  const response = await instance.get('/login/oauth2/twitter');
  return response.data.authorizationUrl;
}
/** @deprecated */
export async function confirmTwitterOAuthLogin(code, state) {
  const response = await instance.post('/login/oauth2/twitter/callback', {
    code,
    state,
  });
  return response.data;
}
/** @deprecated */
export async function getGoogleOAuthCalendarURL() {
  const response = await instance.get('/calendar/oauth2/google');
  return response.data.authorizationUrl;
}
/** @deprecated */
export async function googleOAuthCalendarConfirm(code) {
  const response = await instance.post('/calendar/oauth2/google/callback', {
    code,
  });
  return response.data;
}
/** @deprecated */
export async function revokeGoogleOAuth() {
  const response = await instance.post('/calendar/oauth2/google/revoke-access');
  return response.data;
}

/** @deprecated */
export async function submitEmailVerification(code) {
  const response = await instance.post('/registration/confirm', {
    code,
  });
  return response.data;
}

export default {
  register,
  login,
  refresh,
  logout,
  recoverPassword,
  resetPassword,
  getGoogleOAuthCalendarURL,
  googleOAuthCalendarConfirm,
  submitEmailVerification,
};
