import axios from 'axios';
import useReportErrorInterceptor from '../utils/report-error';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URI + '/events',
  headers: { Accept: 'application/json' },
  withCredentials: true,
});
useReportErrorInterceptor(instance);

/**
 * @param {number} event_id
 * @returns {Promise<CardanoEvent>}
 */
export async function get(event_id) {
  const response = await instance.get(`/${event_id}`);
  return response.data;
}

/**
 * @param {number} event_id
 * @returns {Promise<CardanoEventRaw>}
 */
export async function getRaw(event_id) {
  const response = await instance.get(`/${event_id}/complete-data`);
  return response.data;
}

/**
 * @param { EventDescription | EventLocation | EventTime | EventLinks | EventGraphics } data
 * @returns {Promise<CardanoEvent>}
 */
export async function create(data) {
  const response = await instance.post('', data);
  return response.data;
}

/**
 *
 * @param {number} event_id
 * @param {CardanoUpdateEventData} data
 * @returns {Promise<CardanoEvent>}
 */
export async function update(event_id, data) {
  const response = await instance.patch(`/${event_id}`, data);
  return response.data;
}

export async function getUserEvents() {
  throw new Error('Not Implemented');
}

export async function getAttendEvents() {
  throw new Error('Not Implemented');
}

export async function getPastEvents() {
  throw new Error('Not Implemented');
}

/**
 * @returns {Promise<{ rows: Array<CardanoEventPreview>, count: number }>}
 */
export async function list({
  user_id,
  startAfter,
  startBefore,
  endAfter,
  endBefore,
  search,
  sort,
  limit,
  offest,
} = {}) {
  const response = await instance.get('', {
    params: {
      take: limit,
      skip: offest,
      sort: sort ? `sort[${sort.property}] ${sort.direction}` : undefined,
      filters: {
        userId: {
          $eq: user_id,
        },
        startTimestamp: {
          $gte: startAfter,
          $lte: startBefore,
        },
        endTimestamp: {
          $gte: endAfter,
          $lte: endBefore,
        },
        $or: [
          { title: { $contains: search } },
          { description: { $contains: search } },
        ],
      },
    },
  });
  return response.data;
}
/**
 * @returns {Promise<Array<CardanoEventPreview>>}
 */
export async function listAttended({
  startAfter,
  startBefore,
  endAfter,
  endBefore,
} = {}) {
  const response = await instance.get('/user/events/to-attend', {
    baseURL: import.meta.env.VITE_API_URI,
    params: {
      filters: {
        startTimestamp: {
          $gte: startAfter,
          $lte: startBefore,
        },
        endTimestamp: {
          $gte: endAfter,
          $lte: endBefore,
        },
      },
    },
  });
  return response.data;
}

export async function join(event_id) {
  const response = await instance.post(`/${event_id}/join`);
  return response.status === 200;
}

export async function unjoin(event_id) {
  const response = await instance.delete(`/${event_id}/join`);
  return response.status === 200;
}

export async function isCurrentUserJoined(event_id) {
  const response = await instance.get(`/${event_id}/join`, {
    validateStatus: (status) => status === 200 || status === 404,
  });
  return response.status === 200;
}

export async function getReaction(event_id) {
  const response = await instance.get(`/${event_id}/reaction`, {
    validateStatus: (status) => status === 200 || status === 404,
  });
  return response.status === 200 ? response.data.code : null;
}
export async function setReaction(event_id, reaction) {
  const response = await instance.put(`/${event_id}/reaction`, {
    code: reaction,
  });
  return response.data.code;
}

export async function reactions(event_id) {
  const response = await instance.get(`/${event_id}/reactions_count`, {
    params: { eventId: event_id },
  });
  return response.data.map((i) => ({ code: i.code, count: i._count }));
}
