import axios from 'axios';
import useReportErrorInterceptor from '../utils/report-error';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URI + '/admin/reports',
  headers: { Accept: 'application/json' },
  withCredentials: true,
});
useReportErrorInterceptor(instance);

export async function list({
  reason,
  status,
  createdAfter,
  createdBefore,
  sort,
  limit,
  offest,
  filters,
} = {}) {
  const response = await instance.get('', {
    params: {
      take: limit,
      skip: offest,
      sort: sort && `sort${sort.key} ${sort.order}`,
      filters: filters || {
        reason: {
          $contains: reason,
        },
        status: {
          $eq: status,
        },
        $and: [
          { createdAt: { $gte: createdAfter } },
          { createdAt: { $lte: createdBefore } },
        ],
      },
    },
  });
  return response.data;
}

export async function get(reportId) {
  const response = await instance.get(`/${reportId}`);
  return response.data;
}

export async function solve(reportId, options) {
  const response = await instance.post(`/${reportId}/solve`, options);
  return response.data;
}
