import HeaderFooterLayout from '#/layouts/HeaderFooterLayout.vue';
import HeaderLayout from '#/layouts/HeaderLayout.vue';
import { useUserStoreUnsafe } from '#/store/user';
import MainView from '#/views/MainView.vue';
import { createRouter, createWebHistory } from 'vue-router';

async function mustBeUnauthorized() {
  const store = useUserStoreUnsafe();
  await store.safeInit();
  return store.isAuthorized ? { name: 'Main' } : true;
}
async function mustBeAuthorized() {
  const store = useUserStoreUnsafe();
  await store.safeInit();
  return store.isAuthorized ? true : { name: 'Main' };
}

export default createRouter({
  history: createWebHistory(),
  routes: [
    // Full-page layout
    {
      name: 'SignIn',
      path: '/sign-in',
      component: () => import('#/views/SignInView.vue'),
      beforeEnter: mustBeUnauthorized,
    },
    {
      name: 'SignUp',
      path: '/sign-up',
      component: () => import('#/views/SignUpView.vue'),
      beforeEnter: mustBeUnauthorized,
    },
    {
      name: 'RecoverPassword',
      path: '/password-recover',
      component: () => import('#/views/RecoverPasswordView.vue'),
      beforeEnter: mustBeUnauthorized,
    },
    {
      name: 'ResetPassword',
      path: '/password-reset',
      component: () => import('#/views/ResetPasswordView.vue'),
      beforeEnter: mustBeUnauthorized,
    },
    {
      name: 'OAuth1Success',
      path: '/oauth1/success',
      redirect: (to) => ({
        name: to.query.action === "connect" ? "UserProfileIntegrations" : "Main"
      })
    },
    {
      name: 'OAuth1Fail',
      path: '/oauth1/fail',
      component: () => import('#/views/OAuth1ErrorView.vue'),
      props: (route) => ({
        provider: route.query.provider,
        action: route.query.action,
        error: route.query.error,
      }),
    },
    {
      name: 'OAuth2Success',
      path: '/oauth2/success',
      redirect: (to) => ({
        name: to.query.action === "connect" ? "UserProfileIntegrations" : "Main"
      })
    },
    {
      name: 'OAuth2Fail',
      path: '/oauth2/fail',
      component: () => import('#/views/OAuth2ErrorView.vue'),
      props: (route) => ({
        provider: route.query.provider,
        action: route.query.action,
        error: route.query.error,
      }),
    },
    {
      name: 'EmailVerificationCallback',
      path: '/user/email/verification/callback',
      component: () => import('#/views/EmailVerificationReturn.vue'),
      props: (route) => ({
        payload: route.query.p,
      }),
    },
    {
      name: 'EmailChangeCallback',
      path: '/user/email/change/callback',
      component: () => import('#/views/EmailChangeReturn.vue'),
      props: (route) => ({
        payload: route.query.p,
      }),
    },
    {
      name: 'SubmitAccountVerification',
      path: '/signUp/confirm',
      component: () => import('#/views/SubmitAccoutVerification.vue'),
      props: (route) => ({
        code: route.query.code,
      }),
    },
    {
      name: 'Admin Dashboard',
      path: '/admin',
      redirect: '/admin/events',
      component: () => import('#/views/AdminView.vue'),
      async beforeEnter() {
        const store = useUserStoreUnsafe();
        if (await store.safeInit()) {
          return store.isAdmin ? true : { name: 'Main' };
        }
        return { name: 'SignIn' };
      },
      children: [
        {
          name: 'AdminEvents',
          path: 'events',
          component: () => import('#/views/admin/AdminEvents.vue'),
          props: (route) => ({
            category: route.query.category,
            page: parseInt(route.query.page) || undefined,
            showItems: parseInt(route.query.show_items) || undefined,
          }),
        },
        {
          name: 'AdminEventPage',
          path: 'events/:eventId(\\d+)',
          component: () => import('#/views/admin/AdminEventPage.vue'),
          props: (route) => ({
            eventId: parseInt(route.params.eventId),
            page: parseInt(route.query.page) || undefined,
            showItems: parseInt(route.query.show_items) || undefined,
          }),
        },
        {
          name: 'AdminUsers',
          path: 'users',
          component: () => import('#/views/admin/AdminUsers.vue'),
          props: (route) => ({
            category: route.query.category,
            page: parseInt(route.query.page) || undefined,
            showItems: parseInt(route.query.show_items) || undefined,
          }),
        },
        {
          name: 'AdminUserPage',
          path: 'users/:userId',
          component: () => import('#/views/admin/AdminUserPage.vue'),
          props: (route) => ({
            userId: parseInt(route.params.userId),
            page: parseInt(route.query.page) || undefined,
            eventsPage: parseInt(route.query.events_page) || undefined,
            commentsPage: parseInt(route.query.comments_page) || undefined,
            reportsPage: parseInt(route.query.reports_page) || undefined,
            showItems: parseInt(route.query.show_items) || undefined,
            eventCategory: route.query.event_category,
            eventsSearch: route.query.events_search,
            commentsSearch: route.query.comments_search,
            reportCategory: route.query.report_category,
          }),
        },
        {
          name: 'AdminNotifications',
          path: 'notifications',
          component: () => import('#/views/admin/AdminNotifications.vue'),
        },
        {
          name: 'AdminReports',
          path: 'reports',
          component: () => import('#/views/admin/AdminReports.vue'),
          props: (route) => ({
            page: route.query.page ? parseInt(route.query.page) : undefined,
            showItems: route.query.show_items
              ? parseInt(route.query.show_items)
              : undefined,
          }),
        },
        {
          name: 'AdminReportPage',
          path: 'reports/:reportId',
          component: () => import('#/views/admin/AdminReportPage.vue'),
          props: true,
        },
        {
          name: 'AdminStatistics',
          path: 'statistics',
          component: () => import('#/views/admin/AdminStatistics.vue'),
        },
      ],
    },
    // Header + Footer layout
    {
      name: 'Header + Footer layout',
      component: HeaderFooterLayout,
      path: '',
      children: [
        {
          // If user is authorized redirect to Calendar otherwise to Homepage
          name: 'ConditionalRedirect',
          path: '',
          component: () => import('#/views/ConditionalRedirect.vue'),
        },
        {
          name: 'Main',
          path: '/',
          component: MainView,
          redirect: { name: 'Calendar' },
          children: [
            {
              name: 'Calendar',
              path: 'calendar',
              component: () => import('#/views/main/Calendar.vue'),
            },
            {
              name: 'EventGrid',
              path: 'event-grid',
              component: () => import('#/views/main/EventGrid.vue'),
              props: ({ query }) => ({ timescope: query.scope }),
            },
          ],
        },
        {
          name: 'Homepage',
          path: '/home',
          component: () => import('#/views/HomeView.vue'),
        },
        {
          name: 'EventsForDay',
          path: '/events-for-day/:day(\\d{4}-\\d{2}-\\d{2})',
          component: () => import('#/views/EventsForDayView.vue'),
          props: true,
        },
        {
          name: 'CreateEvent',
          path: '/create-event',
          component: () => import('#/views/CreateEventView.vue'),
          beforeEnter: mustBeAuthorized,
        },
        {
          name: 'SearchResult',
          path: '/search-result',
          component: () => import('#/views/SearchResultView.vue'),
          props: (route) => ({
            searchTarget: route.query.searchTarget,
            searchBy: route.query.searchBy,
          }),
        },
        {
          name: 'AboutUs',
          path: '/about-us',
          component: () => import('#/views/AboutUsView.vue'),
        },
        {
          name: 'PrivacyPolicy',
          path: '/privacy-policy',
          component: () => import('#/views/PrivacyPolicyView.vue'),
        },
        {
          name: 'TermsAndConditions',
          path: '/terms-and-conditions',
          component: () => import('#/views/TermsAndConditionsView.vue'),
        },
        {
          name: 'Changelog',
          path: '/changelog',
          component: () => import('#/views/ChangelogView.vue'),
        },
      ],
    },
    // Header only layout
    {
      name: 'Header only layout',
      component: HeaderLayout,
      children: [
        {
          path: '/404',
          name: 'Page404',
          component: () => import('#/views/Page404.vue'),
        },
        {
          name: 'EventPage',
          path: '/events/:eventId(\\d+):pathTitle(-.*)?',
          component: () => import('#/views/EventView.vue'),
          props: true,
        },
        {
          name: 'EditEvent',
          path: '/edit-events/:eventId(\\d+)',
          component: () => import('#/views/edit-event/EditEventView.vue'),
          beforeEnter: mustBeAuthorized,
          props: true,
        },
        {
          name: 'UserProfile',
          path: '/profile',
          component: () => import('#/views/ProfileView.vue'),
          beforeEnter: mustBeAuthorized,
          redirect: '/profile/bio',
          children: [
            {
              name: 'UserProfileMyEvents',
              path: 'my-events',
              component: () => import('#/views/profile/ProfileMyEvents.vue'),
            },
            {
              name: 'UserProfileAttendedEvents',
              path: 'attended-events',
              component: () => import('#/views/profile/ProfileAttendedEvents.vue'),
            },
            {
              name: 'UserProfileNotifications',
              path: 'notifications',
              component: () =>
                import('#/views/profile/ProfileNotifiactions.vue'),
            },
            {
              name: 'UserProfileAccount',
              path: 'account',
              component: () => import('#/views/profile/ProfileAccount.vue'),
            },
            {
              name: 'UserProfileBio',
              path: 'bio',
              component: () => import('#/views/profile/ProfileBio.vue'),
            },
            {
              name: 'UserProfileWallet',
              path: 'wallet',
              component: () => import('#/views/profile/ProfileWallet.vue'),
            },
            {
              path: 'statistics',
              component: () => import('#/views/profile/ProfileStatistics.vue'),
            },
            {
              path: 'settings',
              component: () => import('#/views/profile/ProfileSettings.vue'),
            },
            {
              name: 'UserProfileIntegrations',
              path: 'integrations',
              component: () =>
                import('#/views/profile/ProfileIntegrations.vue'),
            },
          ],
        },
        {
          path: '/:pathMatch(.*)*',
          redirect: { title: 'Page404' },
        },
      ],
    },
  ],
});
