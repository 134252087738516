/**
 * @param {string | undefined} variable
 * @param {boolean} fallback
 * @returns {boolean}
 */
export default function parseBooleanEnv(variable, fallback = false) {
  if (variable === 'true') return true;
  else if (variable === 'false') return false;
  else return fallback;
}
