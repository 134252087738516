import { onBeforeMount, onBeforeUnmount, computed, watch, toValue } from 'vue';

export default function useTitle({ title, subtitle } = {}) {
  const previous = new TitleObject();
  const current = computed(() => new TitleObject({
    title: toValue(title),
    subtitle: toValue(subtitle)
  }));


  onBeforeMount(() => {
    document.title = current.value.toString();
    watch(current, (newValue) => (document.title = newValue.toString()));
  })
  onBeforeUnmount(() => {
    document.title = previous.toString();
  });
  return current;
}

class TitleObject {
  constructor({ title, subtitle } = {}) {
    const parts = document.title.split('—');
    this.title = title ?? parts[0];
    this.subtitle = subtitle ?? parts[1] ?? null;
  }
  toString() {
    return this.subtitle ? `${this.title} — ${this.subtitle}` : this.title;
  }
}
