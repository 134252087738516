import GlobalSVGDefs from './GlobalSVGDefs.js';
import { createContext, GlobalSVGDefsInjectionKey } from './context.js';





/**
 * This plugin is used to create a global svg definitions.
 * 
 * The motivation was to fix _the gradient disappearing issue_:
 * > If you define a gradient inside a component template and then hide (`display: none`) the first instance in DOM,
 * > then the gradient will disappear from all other instances even they have their own definitions.
 */
export default {
  install: (app) => {
    app.component('GlobalSVGDefs', GlobalSVGDefs);
    app.provide(GlobalSVGDefsInjectionKey, createContext());
  },
};
