<script setup>
import ChevronDown from '#/elements/icons/ChevronDown.vue';
import UserIcon from '#/elements/icons/UserIcon.vue';
import { useUserStore } from '#/store/user';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';

const userStore = useUserStore();
</script>

<template>
  <Menu
    as="div"
    class="relative"
  >
    <MenuButton class="flex items-center gap-2 text-xs font-semibold">
      <!-- User avatar -->
      <img
        v-if="userStore.avatarUrl"
        class="size-10 rounded-full object-cover"
        :src="userStore.avatarUrl"
      >
      <!-- Fallback avatar -->
      <div
        v-else
        class="size-10 rounded-full border border-gray-400"
        role="presentation"
      >
        <UserIcon
          class="m-auto h-full stroke-gray-400 stroke-1"
          aria-hidden="true"
        />
      </div>
      {{ userStore.fullName }}
      <ChevronDown class="stroke-gray-900 dark:stroke-gray-100" />
    </MenuButton>

    <Transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-gray focus:outline-none dark:bg-gray-800 dark:shadow-gray-black"
      >
        <div class="py-2">
          <MenuItem v-slot="{ active }">
            <RouterLink
              :to="{ name: 'UserProfile' }"
              class="block whitespace-nowrap px-4 text-xs font-semibold leading-6"
              :class="{ 'bg-gray-100 dark:bg-gray-700': active }"
            >
              Profile
            </RouterLink>
          </MenuItem>
          <MenuItem
            v-if="userStore.isAdmin"
            v-slot="{ active }"
          >
            <RouterLink
              :to="{ name: 'Admin Dashboard' }"
              class="block whitespace-nowrap px-4 text-xs font-semibold leading-6"
              :class="{ 'bg-gray-100 dark:bg-gray-700': active }"
            >
              Admin Dashboard ⚙
            </RouterLink>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button
              type="button"
              class="block w-full whitespace-nowrap px-4 text-left text-xs font-semibold leading-6"
              :class="{ 'bg-gray-100 dark:bg-gray-700': active }"
              @click="
                async () => {
                  await userStore.logout();
                  $router.push({ name: 'Main' });
                }
              "
            >
              Log Out
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </Transition>
  </Menu>
</template>
