<template>
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.86831 9.00726C1.86831 10.8061 2.59877 12.4236 3.77875 13.5949C6.33538 16.1328 10.4794 16.1328 13.036 13.5949L14.3565 14.9057C11.0694 18.1686 5.74539 18.1686 2.4583 14.9057C0.941177 13.3997 0 11.3081 0 9.00726C0 6.70646 0.941177 4.61482 2.4583 3.10884C5.74539 -0.154118 11.0694 -0.154118 14.3565 3.10884C15.0307 3.77816 15.5786 4.55904 16 5.40964L14.5672 6.83195L14.0193 7.37578L10.7884 10.583L9.46796 11.8937L8.4144 12.9395L7.36084 11.8937L4.01756 8.57499L5.07112 7.52917L8.4144 10.8479L9.73486 9.53714L12.9658 6.32996L13.8648 5.43753C13.626 5.07498 13.345 4.74032 13.036 4.4196C10.4794 1.88174 6.33538 1.88174 3.77875 4.4196C2.59877 5.59092 1.86831 7.22239 1.86831 9.00726Z"
      fill="url(#paint0_linear_409_12673)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_409_12673"
        x1="14.7076"
        y1="17.4435"
        x2="1.68438"
        y2="2.20515"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0056C7" />
        <stop
          offset="1"
          stop-color="#22ABF9"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
