import DiscordLogoIcon from '#/elements/icons/DiscordLogoIcon.vue';
import FacebookLogoIcon from '#/elements/icons/FacebookLogoIcon.vue';
import GoogleLogoIcon from '#/elements/icons/GoogleLogoIcon.vue';
import LinkedInLogoIcon from '#/elements/icons/LinkedInLogoIcon.vue';
import TelegramLogoIcon from '#/elements/icons/TelegramLogoIcon.vue';
import TwitterLogoIcon from '#/elements/icons/TwitterLogoIcon.vue';

export const Socials = Object.freeze({
  Twitter: 'Twitter',
  Telegram: 'Telegram',
  Discord: 'Discord',
  Google: 'Google',
  Facebook: 'Facebook',
  LinkedIn: 'Linkedin',
});

export const SocialNames = Object.freeze({
  [Socials.Twitter]: 'X (Twitter)',
  [Socials.Telegram]: 'Telegram',
  [Socials.Discord]: 'Discord',
  [Socials.Google]: 'Google',
  [Socials.Facebook]: 'Facebook',
  [Socials.LinkedIn]: 'LinkedIn',
});

export const SocialColors = Object.freeze({
  [Socials.Twitter]: '#41ABE1',
  [Socials.Telegram]: '#22ABF9',
  [Socials.Discord]: '#5562EA',
  [Socials.Google]: '#4285F4',
  [Socials.Facebook]: '#1080F8',
  [Socials.LinkedIn]: '#0A66C2',
});

export const SocialLogoURLs = Object.freeze({
  [Socials.Twitter]: '/logos/twitter-logo.svg',
  [Socials.Telegram]: '/logos/telegram-logo.svg',
  [Socials.Discord]: '/logos/discord-logo.svg',
  [Socials.Google]: '/logos/google-logo.svg',
  [Socials.Facebook]: '/logos/facebook-logo.svg',
  [Socials.LinkedIn]: '/logos/linkedin-logo.svg',
});

export const SocialLogoComponents = Object.freeze({
  [Socials.Twitter]: TwitterLogoIcon,
  [Socials.Telegram]: TelegramLogoIcon,
  [Socials.Discord]: DiscordLogoIcon,
  [Socials.Google]: GoogleLogoIcon,
  [Socials.Facebook]: FacebookLogoIcon,
  [Socials.LinkedIn]: LinkedInLogoIcon,
});
