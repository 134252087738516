import { defineComponent, onBeforeMount, onBeforeUnmount } from 'vue';
import { useContext } from './context';

export default defineComponent((_props, { slots, attrs }) => {
  const context = useContext();

  if (!attrs.id) throw new Error('No ID provided.');

  onBeforeMount(() => context.registerElement(attrs.id, slots.default));
  onBeforeUnmount(() => context.unregisterElement(attrs.id, slots.default));
  return () => null;
});
