import axios from 'axios';
import useReportErrorInterceptor from '../utils/report-error';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URI + '/admin/users',
  headers: { Accept: 'application/json' },
  withCredentials: true,
});
useReportErrorInterceptor(instance);

export async function list({
  isBlocked,
  searchByName,
  offset,
  limit,
  sort,
  filters,
} = {}) {
  const response = await instance.get('', {
    params: {
      skip: offset,
      take: limit,
      filters: filters || {
        fullName: { $contains: searchByName },
        isBlocked: { $eq: isBlocked },
      },
      sort: sort && `sort${sort.key} ${sort.order}`,
    },
  });
  return response.data;
}

export async function get(user_id) {
  const response = await instance.get(`/${user_id}`);
  return response.data;
}

export async function getCreatedEvents(
  user_id,
  { search, offset, limit } = {},
) {
  const response = await instance.get(`/${user_id}/events/created`, {
    params: {
      skip: offset,
      take: limit,
      filters: {
        $or: [
          { title: { $contains: search } },
          { user: { fullName: { $contains: search } } },
        ],
      },
    },
  });
  return response.data;
}
export async function getVisitedEvents(
  user_id,
  { search, offset, limit } = {},
) {
  const response = await instance.get(`/${user_id}/events/visited`, {
    params: {
      skip: offset,
      take: limit,
      filters: {
        $or: [
          { title: { $contains: search } },
          { user: { fullName: { $contains: search } } },
        ],
      },
    },
  });
  return response.data;
}
export async function getComments(user_id, { search, offset, limit } = {}) {
  const response = await instance.get(`/${user_id}/comments`, {
    params: {
      skip: offset,
      take: limit,
      filters: {
        text: { $contains: search },
      },
    },
  });
  return response.data;
}
export async function getCreatedReports(user_id, { offset, limit } = {}) {
  const response = await instance.get(`/${user_id}/reports/created`, {
    params: {
      skip: offset,
      take: limit,
    },
  });
  return response.data;
}
export async function getRecivedReports(user_id, { offset, limit } = {}) {
  const response = await instance.get(`/${user_id}/reports/received`, {
    params: {
      skip: offset,
      take: limit,
    },
  });
  return response.data;
}

export async function block(user_id, data) {
  const response = await instance.put(`/${user_id}/blocking-data`, data);
  return response.data;
}
