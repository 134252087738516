<script setup>
import Button from '#/components/Button.vue';
import ToggleTheme from '#/components/ToggleTheme.vue';
import UserProfile from '#/components/UserProfile.vue';
import CardanoCalendarLogo from '#/elements/CardanoCalendarLogo.vue';
import BellIcon from '#/elements/icons/BellIcon.vue';
import LoupeIcon from '#/elements/icons/LoupeIcon.vue';
import { useUserStore } from '#/store/user';
import { useRouter } from 'vue-router';

const userStore = useUserStore();
const router = useRouter();
</script>

<template>
  <header class="px-10">
    <div class="mx-auto flex max-w-xxl justify-between py-6">
      <div class="flex items-center gap-4">
        <RouterLink :to="{ name: 'Main' }">
          <span class="sr-only">Cardano Calendar</span>
          <CardanoCalendarLogo />
        </RouterLink>
        <ToggleTheme />
      </div>

      <div>
        <div class="flex gap-12">
          <div class="flex flex-1 items-center justify-center">
            <div class="w-full max-w-lg lg:max-w-xs">
              <label
                for="header-search"
                class="sr-only"
              >Search</label>
              <div class="relative text-gray-400 focus-within:text-gray-600">
                <div
                  class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-6"
                >
                  <LoupeIcon
                    class="size-4 fill-gray-500"
                    aria-hidden="true"
                  />
                </div>
                <input
                  id="header-search"
                  class="block w-full rounded-lg border border-gray-300 bg-transparent py-1.5 pl-14 pr-3 text-gray-900 placeholder:text-gray-600 focus:border-primary focus:ring-1 focus:ring-primary focus-visible:outline-none dark:border-gray-600 dark:text-gray-100 dark:placeholder:text-gray-400 dark:focus:border-primary sm:text-xs sm:leading-5.5"
                  placeholder="Search"
                  type="search"
                  name="search"
                  @keydown.enter="
                    $router.push({
                      name: 'SearchResult',
                      query: {
                        searchBy: $event.target.value,
                        searchTarget: $route.query.searchTarget,
                      },
                    })
                  "
                >
              </div>
            </div>
          </div>

          <div class="flex items-center gap-6">
            <template v-if="userStore.isAuthorized">
              <RouterLink
                :to="{ name: 'CreateEvent' }"
                tabindex="-1"
              >
                <Button
                  class="small"
                  text="Create Event"
                />
              </RouterLink>

              <RouterLink
                class="py-1 text-xs font-semibold"
                :to="{ name: 'UserProfileMyEvents' }"
              >
                My Events
              </RouterLink>

              <RouterLink :to="{ name: 'UserProfileNotifications' }">
                <BellIcon role="presentation" />
                <span class="sr-only">Notifications</span>
              </RouterLink>
            </template>

            <template v-if="!userStore.isInitialized">
              <div class="flex items-center gap-2 text-xs font-semibold">
                <div class="skeleton-block size-10 rounded-full" />
                <span class="skeleton-text w-20" />
              </div>
            </template>
            <UserProfile v-else-if="userStore.isAuthorized" />
            <Button
              v-else
              class="small secondary"
              text="Sign Up"
              @click="router.push({ name: 'SignUp' })"
            />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
