/**
 * Resize an image file to fit the threshold.
 * If image size doesn't exceed the threshold returns original file.
 * Throws if image cannot be loaded or canvas context is unaccessible.
 * @param {File} image file to resize
 * @param {string} name new file name
 * @param {number} threshold target size in pixels for a least dimension (default to 160)
 * @returns {Promise<File>} if image size exceed the threshold returns resized image of `image/webp` type, otherwise returns original file.
 */
export default async function resizeImage(image, name, threshold = 160) {
  // Transform File to Image object
  const img = document.createElement('img');
  img.src = URL.createObjectURL(image);
  await new Promise((r, j) => {
    img.onload = r;
    img.onerror = j;
  });

  // Resize the image via canvas
  let file = image;
  if (img.height > threshold || img.width > threshold) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (ctx) {
      const leastDimension = Math.min(img.width, img.height);
      const scaleFactor = threshold / leastDimension;
      const targetHeight = img.height * scaleFactor;
      const targetWidth = img.width * scaleFactor;

      canvas.height = targetHeight;
      canvas.width = targetWidth;
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
      const blob = await new Promise((r, j) =>
        canvas.toBlob((blob) => (blob ? r(blob) : j()), 'image/webp', 1),
      );
      file = new File([blob], name, {
        type: 'image/webp',
      });
    } else {
      throw new NoCanvasError();
    }
  }
  return file;
}

export class NoCanvasError extends Error {
  constructor() {
    super('Canvas context is unaccessible');
    this.name = 'NoCanvasError';
  }
}
