<script setup>
import { v4 as uuidv4 } from 'uuid'; 

defineProps({
  heading: {
    type: String,
    required: true,
  }
});

const uuid = uuidv4();
</script>
<template>
  <article
    :id="`socials-section-${uuid}`"
    class="bg-gray-200 pb-12 pt-6 text-center dark:bg-gray-700 sm:pb-16 sm:pt-9"
    :aria-labelledby="`socials-header-${uuid}`"
  >
    <h2
      :id="`socials-header-${uuid}`"
      class="text-2xl lg:text-h-medium"
    >
      {{ heading }}
    </h2>
    <div class="mt-8 flex justify-center gap-2 sm:gap-4">
      <slot />
    </div>
  </article>
</template>