<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="#0A66C2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.27556 4.66669C5.38699 4.66669 4.66667 5.38701 4.66667 6.27557V25.7244C4.66667 26.613 5.387 27.3333 6.27556 27.3333H25.7245C26.613 27.3333 27.3333 26.613 27.3333 25.7244V6.27557C27.3333 5.38701 26.613 4.66669 25.7244 4.66669H6.27556ZM9.7543 11.7058C10.8403 11.7058 11.7206 10.8255 11.7206 9.7395C11.7206 8.65353 10.8403 7.77317 9.7543 7.77317C8.66832 7.77317 7.78797 8.65353 7.78797 9.7395C7.78797 10.8255 8.66832 11.7058 9.7543 11.7058ZM13.5214 13.159H16.7803V14.6519C16.7803 14.6519 17.6646 12.8832 20.0708 12.8832C22.2171 12.8832 23.9952 13.9406 23.9952 17.1635V23.9597H20.6181V17.987C20.6181 16.0858 19.6031 15.8767 18.8296 15.8767C17.2245 15.8767 16.9496 17.2613 16.9496 18.235V23.9597H13.5214V13.159ZM11.4684 13.159H8.0402V23.9597H11.4684V13.159Z"
    />
  </svg>
</template>
