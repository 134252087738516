import UserEventsAPI from 'axios';
import useReportErrorInterceptor from '../utils/report-error';
import { ApiError } from './errors';

const instance = UserEventsAPI.create({
  baseURL: import.meta.env.VITE_API_URI + '/user/events',
  headers: { Accept: 'application/json' },
  withCredentials: true,
});
useReportErrorInterceptor(instance);

/**
 * @param {object} [params]
 * @param {number} [params.skip]
 * @param {number} [params.take]
 * @param {"createdAt" | "endTimestamp" | "startTimestamp"} [params.orderBy]
 * @param {"desc" | "asc"} [params.orderDir]
 * @returns {Promise<{ rows: Array<CardanoEventPreview2>, count: number }>}
 */
export async function listOwnEvents(params) {
  const response = await instance.get('/own', { params });
  return response.data;
}

/**
 * @param {object} [params]
 * @param {number} [params.skip]
 * @param {number} [params.take]
 * @param {"createdAt" | "endTimestamp" | "startTimestamp"} [params.orderBy]
 * @param {"desc" | "asc"} [params.orderDir]
 * @returns {Promise<{ rows: Array<CardanoEventPreview2>, count: number }>}
 */
export async function listAttendEvents(params) {
  const response = await instance.get('/attend', { params });
  return response.data;
}

/**
 * Get an event the user own
 * @param {number} id
 * @returns {Promise<CardanoOwnEvent>}
 */
export async function getOwnEvent(id) {
  try {
    const response = await instance.get(`/own/${id}`);
    return response.data;
  } catch (error) {
    if (error.response.status === 404 || error.response.status === 403) {
      throw new ApiError(error.response.data);
    }
    throw error;
  }
}

/**
 * @param {number} id
 * @param {unknown} data
 * @returns {Promise<CardanoOwnEvent>}
 */
export async function updateOwnEvent(id, data) {
  try {
    const response = await instance.patch(`/own/${id}`, data);
    return response.data;
  } catch (error) {
    if ([400,403,404].includes(error.response.status)) {
      throw new ApiError(error.response.data);
    }
    throw error;
  }
}
