import parseBooleanEnv from '#/utils/parse-boolean';

/**
 * This is main application config
 */
const AppConfig = {
  // Vite variables
  MODE: import.meta.env.MODE,
  BASE_URL: import.meta.env.BASE_URL,
  PROD: import.meta.env.PROD,
  DEV: import.meta.env.DEV,
  SSR: import.meta.env.SSR,
  // Project variables
  APP_VERSION: import.meta.env.VITE_APP_VERSION,
  API_URI: import.meta.env.VITE_API_URI,
  NFT_POLICY_ID: import.meta.env.VITE_NFT_POLICY_ID,
  SENTRY_ENABLE: parseBooleanEnv(
    import.meta.env.VITE_SENTRY_ENABLE,
    import.meta.env.PROD,
  ),
  SENTRY_DNS: import.meta.env.VITE_SENTRY_DNS,
};

export default AppConfig;
